.pv-container {}

.pv-container .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

}

.pv-container .head h5.ant-typography {
    margin-bottom: 0.2rem;
}
.pv-container .head .date-range {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.pv-container .head .date-range .ant-picker {
    border-radius: 8px;
    width: 172px;
    height: 36px;
    /* border: 1px solid #000; */
}

.pv-container .btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

.pv-container .btn button {
    width: 133px;
    height: 24px;
    background: #070707;
    border-radius: 87px;
    color: #fff;
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.pv-container .pv-graph{
    padding: 40px 0 70px 0;
}