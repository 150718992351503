* {
  /* scroll-behavior: smooth; */
}
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: var(--background);
}

th,
thead tr {
  color: var(--primary-color) !important;
  margin: 0;
  font-size: 18px;
}
td {
  color: var(--text) !important;
}
.search-input {
  height: 38px;
  border-radius: 15px;
  margin-left: 10px;
}
.btn-outlined {
  background-color: transparent !important;
  border: 1px solid var(--primaryText) !important;
  color: var(--primaryText) !important;
  border-radius: 50px;
  height: 47px;
  width: 156px;
}
.squared-btn {
  background-color: transparent !important;
  border: 1px solid var(--primaryText) !important;
  color: var(--primaryText) !important;
  border-radius: 4px !important;
  height: auto;
  width: auto !important;
  margin: 0 5px;
}
.squared-secondary {
  border: 1px solid var(--secondary-color) !important;
  color: var(--secondary-color) !important;
}
.squared-green {
  border: 1px solid green !important;
  color: green !important;
}
.secondary-border {
  border: 1px solid var(--secondary-color) !important;
}
.c-white {
  color: var(--text);
}
.small-btn {
  height: 35px !important;
}
@media (max-width: 770px) {
  h1 {
    font-size: 25px !important;
  }
}

@media (max-width: 550px) {
  h1 {
    font-size: 20px !important;
  }
}

.ck-editor__editable {
  min-height: 400px;
}
.ck-editor__editable * {
  color: black !important;
}

/* progress bar line  */
.ant-progress-inner {
  border-radius: 0 !important;
}
/* progress bar line  */

/* badge */
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  transform: translate(0%, -3%);
}

.ant-badge-dot {
  display: none;
}
.multi-axis-chart svg {
  background-color: transparent !important;
}
.white-card {
  box-shadow: 0px 1px 4px #13152329;
  border-radius: 6px;
  background-color: var(--background);
  padding: 20px 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.flex-around {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
}

.liquid-circle .g2-html-annotation {
  font-size: 15px !important;
  /* color: #7e84a3 !important; */
  /* font-weight: 600 !important; */
  letter-spacing: 0.4px;
  /* left: 116.5px !important;
  top: 50px !important; */
}

.liquid-circle-completed .g2-html-annotation {
  font-size: 15px !important;
  color: #fff !important;
  /* left: 114.5px !important;
  top: 60px !important; */
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  width: 100%;
}

.histogram {
  margin: 50px 0;
}
.histogram h4 {
  margin: 30px 0;
}
.digitalization-header {
  margin-top: 5rem;
}
.digiDropdown .ant-select-single .ant-select-selector {
  display: block;
}

html {
  overflow-x: hidden;
  font-size: 100% !important;
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 100% !important;
  }
}

@media only screen and (max-width: 90em) {
  html {
    font-size: 100% !important;
  }
}

@media only screen and (max-width: 64em) {
  html {
    font-size: 90% !important;
  }
}

@media only screen and (max-width: 48em) {
  html {
    font-size: 80% !important;
  }
}

@media only screen and (max-width: 26.5625em) {
  html {
    font-size: 80% !important;
  }
}

@media only screen and (max-width: 23.4375em) {
  html {
    font-size: 70% !important;
  }
}

@media only screen and (max-width: 20em) {
  html {
    font-size: 60% !important;
  }
}
@media only screen and (max-width: 7.5em) {
  html {
    font-size: 40% !important;
  }
}
.wrapper p {
  position: absolute;
  top: 50%;
  z-index: 100;
  width: 100%;
  text-align: center;
}

/* for the pen */
html,
body {
  margin: 0;
  min-height: 100%;
  background-color: #f2f2f2;
}

/* waves */
.ocean {
  height: 80px; /* change the height of the waves here */
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%230099FF'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s -3s linear infinite;

  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}
.wave svg {
  stroke: black;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;

  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 20s -1s linear infinite;

  opacity: 0.5;
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(-50%);
  }
}
