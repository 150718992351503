.hover-container{
    /* padding: 20px; */
    width: 100%;
    /* height: 142px; */
    /* border-radius: 14px; */
    /* background-color: var(--background); */
}
.hover-container .hover-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.hover-container .hover-wrapper p{
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    font-size: 12px;
}
.ant-tooltip{
    min-width : max-content !important;
    max-width : max-content !important;
    background-color: var(--background);
    border-radius: 8px;

}