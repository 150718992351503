.login-main {
  /* background-color: white; */
  border-radius: 6px;
  padding: 20px 30px;
  width: 40%;
  text-align: center;
  /* box-shadow: var(--boxShadow); */
}
.login-main img{
  margin-bottom: 10px;
}
.login-main h2 {
  text-align: center;
  color: var(--primaryText) !important;
}
.login-main p {
  margin-bottom: 30px;
  text-align: center;
  color: var(--secondaryText) !important;
  width: 70%;
  margin: 0 auto;
}
.login-main .login-form {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  margin: 20px 0;
  padding: 30px;
}
.temp-login-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: auto;
}
.signup-main {
  /* align-items: flex-start !important; */
}
.login-main p {
  margin-bottom: 0;
  font-size: 15px;
}
.login-main .login-form {
  margin-bottom: 20px;
}
.login-main .ant-input {
  margin-top: 10px;
  height: 50px;
}
.login-main button {
  width: 100%;
  height: 50px;
  font-weight: bold;
  letter-spacing: 1px;
}

.login-main .remember-check {
  display: flex;
  justify-content: space-between;
  color: var(--text);
  margin: 20px 0;
}
.login-main .remember-check a {
  cursor: pointer;
  color: var(--secondary-color);
}
.login-main .signup {
  color: var(--text);
  text-align: center;
  margin-top: 20px;
}
.login-main .signup a {
  cursor: pointer;
  color: var(--secondary-color);
}

@media (max-width: 770px) {
  .login-main {
    width: 70%;
  }
  .login-main p {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .login-main {
    width: 100%;
  }
}
