.product-main {
  padding: 50px 0;
}
.product-main h2 {
  /* text-align: center; */
  color: var(--text) !important;
}

.product-main .product-form {
  /* width: 80%; */
  margin: 20px auto;
}
.product-main .product-form .ant-input {
  height: 50px;
  border-radius: 10px;
}
.product-main button {
  background: linear-gradient(
    91.4deg,
    #70b3ea 1.53%,
    rgba(0, 120, 217, 0.88) 98.41%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

@media (max-width: 550px) {
  .product-main .product-form {
    width: 100%;
    margin: 20px auto;
  }
}
