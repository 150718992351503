.home-main h1 {
  margin-bottom: 0;
  /* color: black !important; */
}

.electric-cost,
.electric-consumption {
  display: flex;
  min-height: 6rem;
  height: 100%;
  align-items: flex-end;
  /* justify-content: space-between; */
}
.electric-consumption {
  justify-content: space-between;
}
.electric-cost {
  width: 100%;
}
.electric-consumption-outer {
  height: 100%;
}

.electric-cost-outer {
  display: grid;
  grid-auto-flow: column;
  gap: 5%;
}
.electric-cost-outer > :not(:last-child),
.electric-consumption-outer > :not(:last-child) {
  margin-bottom: 1rem;
}

.electric-cost-outer .title,
.electric-consumption-outer .title {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0;
}

.electric-cost-outer-dropdown,
.electric-consumption-outer-dropdown,
.stacked-pie-outer-dropdown {
  color: var(--secondaryText);
  font-size: 1rem;
}
.electric-cost-outer,
.electric-consumption-outer,
.stacked-pie-outer {
  box-shadow: 0px 1px 4px #13152329;
  border-radius: 6px;
  background-color: var(--background);
  padding: 20px 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.electric-consumption .electric-consumption-right {
  width: 60%;
  justify-content: flex-end;
}

.electric-consumption .electric-consumption-left .title,
.electric-cost .electric-cost-left .title {
  color: var(--text) !important;
  /* font-weight: 400 !important; */
}
.electric-consumption .electric-consumption-left .sub-title,
.electric-cost .electric-cost-left .sub-title {
  color: var(--text2) !important;
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 42px;
  margin-bottom: 0;
}
.electric-consumption .electric-consumption-left .percentage-wrapper {
  display: flex;
  align-items: flex-end;
}

.electric-consumption
  .electric-consumption-left
  .percentage-wrapper
  .percentage,
.electric-consumption .electric-consumption-left .percentage-wrapper .text,
.electric-cost .electric-cost-left .percentage-wrapper .percentage,
.electric-cost .electric-cost-left .percentage-wrapper .text {
  color: var(--secondaryText);
  font-size: 12px;
}
.electric-cost .electric-cost-left .percentage-wrapper .percentage,
.electric-consumption
  .electric-consumption-left
  .percentage-wrapper
  .percentage {
  font-weight: 600;
  font-size: 1rem;
  /* display: flex;
  align-items: center; */
}
.electric-consumption-right .bar-chart-warpper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.electric-consumption-right .bar-chart-warpper > :nth-child(1) {
  width: 40%;
  max-width: 5rem;
}
.electric-consumption-right .bar-chart-warpper > :nth-child(2) {
  width: 60%;
}
.contributor-peak-card
  .electric-consumption-right
  .bar-chart-warpper
  > :nth-child(2) {
  width: 80%;
}
.electric-consumption-right .bar-chart-warpper p {
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 0.8rem;
  color: #7e84a3;
  /* color: var(--secondaryText); */
}

.percentage-wrapper-right {
  width: 100%;
}
.percentage-wrapper-right :nth-child(1) .ant-progress-bg {
  background-color: var(--primary);
}
.percentage-wrapper-right :nth-child(2) .ant-progress-bg {
  background-color: var(--green);
}
.percentage-wrapper-right :nth-child(3) .ant-progress-bg {
  background-color: var(--yellow);
}
.percentage-wrapper-right :nth-child(4) .ant-progress-bg {
  background-color: var(--orange);
}
.electric-cost-left {
  width: 30%;
}
.electric-cost-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60%;
}
.electric-cost-right > :not(:last-child) {
  margin-right: 1rem;
}
.electric-cost .electric-cost-right .item {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.electric-cost .electric-cost-right .item svg {
  stroke-width: 2;
}
.electric-cost .electric-cost-right .item > :not(:last-child) {
  /* margin-bottom: 0.2rem !important; */
}
.electric-cost .electric-cost-right .item .ant-progress-text {
  font-size: 0.75rem;
}
.electric-cost .electric-cost-right .item .sub-title {
  color: var(--text) !important;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
}

/* .electric-cost-right .ant-progress-text {
  color: var(--secondaryText) !important;
} */
.ant-progress-bg {
  border-radius: 0 10px 10px 0 !important;
}
.ant-progress-inner {
  border-radius: 0 10px 10px 0 !important;
  background-color: #e6e9f4;
}

/* @media (max-width: 1300px) {
  .electric-cost-right .ant-progress-inner {
    width: 3rem !important;
  }
} */
@media (max-width: 1400px) {
  .electric-cost-right > :not(:last-child) {
    margin-right: 0.5rem;
  }
}
@media (max-width: 1300px) {
  .electric-cost .electric-cost-left {
    width: 100%;
  }
  .electric-cost .electric-cost-right {
    width: 100%;
  }
  .electric-consumption .electric-consumption-left {
    width: 100%;
  }
  .electric-consumption .electric-consumption-right {
    width: 100%;
  }

  .electric-cost-right {
    justify-content: space-evenly;
  }
  .electric-cost,
  .electric-consumption {
    flex-direction: column;
  }
  .electric-cost > :not(:last-child),
  .electric-consumption > :not(:last-child) {
    margin-bottom: 1rem;
  }

  .electric-consumption {
    width: 100%;
  }
}
@media (max-width: 770px) {
  .electric-consumption-outer {
    height: auto !important;
  }
  .electric-consumption-outer .column-chart {
    height: 200px !important;
  }
}

@media (max-width: 550px) {
  .electric-cost-outer {
    min-height: auto;
  }
  .electric-cost,
  .electric-consumption {
    flex-direction: column;
  }

  .electric-consumption-outer .column-chart {
    height: 210px !important;
  }
}
