/* .load-peak-main .peak-card {
  min-height: 170px;
} */
.load-peak-main .contributors {
  width: 90% !important;
  margin: 10px auto 0 auto !important;
}
/* .load-peak-main .load-peak-bar {
  height: 300px;
}*/
.load-peak-main .load-peak-bar {
  height: 200px !important;
  /* margin-top: 30px; */
}

.load-peak-main .max-load-peak-card .inner-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.load-peak-main .max-load-peak-card .inner-wrapper > :not(:last-child) {
  margin-right: 20px;
}

.load-peak-main .max-load-peak-card .load-sections .secondary {
  color: black;
  opacity: 0.5;
  /* font-size: 0.5vw; */
}
.load-peak-main
  .max-load-peak-card
  .load-sections
  .secondary.timestamp-wrapper {
  font-size: 0.7vw !important;
}

.load-peak-main .max-load-peak-card .load-sections {
  text-align: center;
}
.load-peak-main .load-actions > :not(:last-child) {
  margin-bottom: 20px;
}

.load-peak-main .load-actions .ant-table tr td {
  padding: 0;
}

.load-peak-main .max-load-peak-card .load-sections h2 {
  background-color: var(--green);
  width: 7vw;
  font-size: 1.2vw;
  height: 7vw;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.load-peak-main .max-load-peak-card .load-sections .percent * {
  font-weight: 600;
  margin: 0;
  /* margin-bottom: 10px; */
  font-size: 0.7vw;
}

.load-peak-main .white-card {
  height: auto !important;
}
.load-peak-main .white-card .ant-table {
  margin: 0 !important;
}
.load-peak-main {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  /* grid-auto-flow: column; */
  /* grid-template: 1fr 1fr 1fr / repeat(200px, minmax(200px, 1fr)); */
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-template-columns: auto auto; */

  /* grid-template-rows: repeat(3, 1fr); */
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  gap: 30px;
  /* column-gap: 30px; */
}
.load-peak-main .max-load-peak-card {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
}
.load-peak-main .contributor-peak-card {
  grid-row: 1 / span 1;
  grid-column: 2 / span 2;
}

.load-peak-main .contributor-peak-area {
  grid-row: 2 / span 1;
  grid-column: 2 / span 2;
}

.load-peak-main .load-peak-bar {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
}

.load-peak-main .load-actions {
  grid-column: 2 / span 2;
  grid-row: 3 / span 1;
}

/* .live-main2 .grid-section .charging {
  grid-column: 2 / span 2;
  grid-row: 2 / span 2;
} */
@media (max-width: 1580px) {
  .load-peak-main .max-load-peak-card .inner-wrapper {
    flex-direction: column;
  }
  .load-peak-main .max-load-peak-card .inner-wrapper > :not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 1480px) {
  .load-peak-main .max-load-peak-card .load-sections h2 {
    background-color: var(--green);
    width: 90px;
    font-size: 15px;
    height: 90px;
  }
}
@media (max-width: 1270px) {
  .load-peak-main .max-load-peak-card .flex-between {
    align-items: center;
    justify-content: center;
  }

  .load-peak-main .load-peak-bar {
    grid-column: 1 / -1;
    grid-row: 3 / span 1;
  }

  .load-peak-main .load-actions {
    grid-column: 1 / -1;
    grid-row: 4 / span 1;
  }
}
@media (max-width: 770px) {
  .load-peak-main .max-load-peak-card .inner-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .load-peak-main .max-load-peak-card .inner-wrapper > :not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0px;
  }
  .load-peak-main .contributors {
    width: 90% !important;
  }

  .load-peak-main .max-load-peak-card {
    grid-column: 1 / -1;
    grid-row: 1 / span 1;
  }

  .load-peak-main .contributor-peak-card {
    grid-row: 2 / span 1;
    grid-column: 1 / -1;
  }

  .load-peak-main .contributor-peak-area {
    grid-row: 3 / span 1;
    grid-column: 1 / -1;
  }
  .load-peak-main .load-peak-bar {
    grid-column: 1 / -1;
    grid-row: 4 / span 1;
  }

  .load-peak-main .load-actions {
    grid-column: 1 / -1;
    grid-row: 5 / span 1;
  }
}

@media (max-width: 550px) {
  .load-peak-main .contributors {
    width: 95% !important;
  }
}

@media (max-width: 450px) {
  .load-peak-main .max-load-peak-card .flex-between {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 390px) {
  .load-peak-main .max-load-peak-card .inner-wrapper {
    flex-direction: column;
  }
  .load-peak-main .max-load-peak-card .inner-wrapper > :not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
